import React from 'react';
import Layout from "../../components/Layout";
import SEO from "../../components/Seo";
import MainFrontpage from "../../components/MainFrontpage";
import MainLeftColumn from "../../components/MainLeftColumn";
import MainRightColumn from "../../components/MainRightColumn";
import { useAlternateLangs } from "../../components/Hreflangs";

// Hreflang data
const alternateLangs = useAlternateLangs(
  // replace with pages x-default
  "/en"
);
  
  const Index = ({ location }) => { // Receive location as a prop
    return (
      <Layout location={location} alternateLangs={alternateLangs}>
        <React.Fragment>
          <SEO
            title="Blue River Mountains | Google Analytics & Conseil SEO"
            lang="fr"
            description="Consultant en SEO et Web Analytics en freelance. Découvrez le SEO ou Google Analytics dans nos tutoriels ou contactez-nous pour obtenir de l'aide sur des problèmes plus avancés."
            image="homepage-screenshot"
            alternateLangs={alternateLangs}
            canonical="/fr"
            datePublished="2024-06-17"
            dateModified="2024-06-17"
          />
          <MainFrontpage>
            {/* Since MainLeftColumn and MainRightColumn need the language, they can consume the context within themselves. */}
            <MainLeftColumn />
            <MainRightColumn />
          </MainFrontpage>
        </React.Fragment>
      </Layout>
    );
  };
  
  export default React.memo(Index);
  
